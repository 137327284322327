import { ListGroup } from "./List";
import Avatar from '../assets/avatar.png';

export default {
    title: 'Data/List',
    component: ListGroup,
    parameters: {
        layout: 'centered'
    },
    tags: ['autodocs'],
    argTypes: {
        backgroundColor: { control: 'color' },
    },
};

export const DefaultList = {
    args: {
        label: 'Track name',
        icon: 'icon',
        avatar: 'avatar',
        color: 'rgb(71 71 71)',
        backgroundColor: 'rgb(221 221 221)',
        hoverColor: 'rgb(211 205 205)',
        padding: '6px 12px',
        display: 'flex',
        flexDirection: 'column',
        gap: '0',
        border: 'solid 1px rgb(197 197 197)',
        borderRadius: '0',
        width: '100%',
        maxWidth: '240px',
        height: '100%',
        font: 'arial',
        fontSize: '0.8rem',
        textAlign: 'center',
        textDecoration: 'none',
        minWidth: '240px',
        justifyContent: 'start',
        items: 
            [
                {label: 'Track One', avatar: Avatar, icon: 'some icon'},
                {label: 'Track Two', avatar: Avatar, icon: ''},
                {label: 'Track Two', avatar: Avatar, icon: 'some icon'},
                {label: 'Track Two', avatar: Avatar, icon: 'some icon'},
                {label: 'Track Two', avatar: Avatar, icon: 'some icon'}
            ]
        
    }
}