import React from 'react';
import './list.scss';

export const ListGroup = ({...props}) => {
    const params = props.args.args;
    return (
        <>
            <u className="listContainer" style={{width: params.width, minWidth: params.minWidth, maxWidth: params.maxWidth, fontFamily: params.font, display: params.display, flexDirection: params.flexDirection, gap: params.gap, textDecoration: params.textDecoration}}>
                {params.items.map((item, index) =>(
                <li className="listItem" key={index} style={{padding: params.padding, border: params.border, borderRadius: params.borderRadius, color: params.color, backgroundColor: params.backgroundColor, fontSize: params.fontSize, justifyContent: params.justifyContent}}><img className="avatar" src={item.avatar} alt={item.label}></img><span>{item.label}</span> <span style={{justifySelf: 'end', textAlign: 'right'}}>{item.icon}</span></li>
                ))}
            </u>
        </>
    )
}
