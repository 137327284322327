import './App.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas, faFontAwesome } from '@fortawesome/free-solid-svg-icons'
import {BrowserRouter as Router, Routes, Route, useParams} from "react-router-dom";
import { faCheckSquare, faCoffee, faList, faGear, faCalendar, faUser } from '@fortawesome/free-solid-svg-icons';
import { ListGroup } from './stories/data/List';
import { DefaultList } from './stories/data/List.stories';

  // library.add(fab, faCheckSquare, faCoffee, faList, faGear, faCalendar, faUser)

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/list" element={<ListGroup args={DefaultList} />}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
